import BaseEntity from "@/models/base/base-entity.model";

export default class SolicitudMejoramientoViviendaMaterialParedesVivienda extends BaseEntity {
  solicitudId: number = 0;
  materialParedesViviendaId: number = 0;
  material: string = "";
  constructor(materialId:number = 0, material = '') {
    super();
    this.id = undefined;
    this.materialParedesViviendaId = materialId;
    this.material = material;
  }
}
