

























import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import BaseVue from "@/core";
import SolicitudProyecto from "@/models/solicitud-proyecto.model";
import Contacto from "@/models/contacto.model";
@Component({})
export default class SolicitudFormDetailComponent extends BaseVue {
  @Prop() public value!: SolicitudProyecto;
  constructor(){
    super();
  }
  
}

