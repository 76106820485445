import Vue from 'vue';

//Buefy
import Buefy from 'buefy';
Vue.use(Buefy);

//moment
import moment from 'moment';
moment.locale(process.env.VUE_APP_LOCALE || 'es-do');

import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
Vue.use(VueFormWizard);

//VeeValidate
import es from 'vee-validate/dist/locale/es'
import VeeValidate, { Validator } from 'vee-validate';
// Install the Plugin.
Vue.use(VeeValidate);
Validator.localize('es', es);

import Axios from 'axios';
Vue.prototype.$http = Axios;

import Print from "vue-print-nb";
Vue.use(Print);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import money from "v-money";
Vue.use(money);



const googleAnalyticsId = process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID;
import VueGtag from "vue-gtag";
if (googleAnalyticsId){
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID },
});
} 
