






























































import BaseVue from "@/core";
import { Component, Vue, Watch } from "vue-property-decorator";
import SolicitudProyecto from "@/models/solicitud-proyecto.model";
import SolicitudInvalida from "../components/solicitud/solicitud-invalida.vue";
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
import IEntityValidationResult from "@/core/interfaces/entity-validation-result.interface";
import Helpers from "@/core/helpers";
@Component({
  components: {
    SolicitudInvalida,
    VueRecaptcha,
  },
})
export default class ConsultaSolicitud extends BaseVue {
  recaptchaKey = process.env.VUE_APP_RECAPTCHAKEY;
  cedula: string = String(this.$route.query.cedula) || "";
  public model: SolicitudProyecto | null;
  isLoading: boolean = false;
  reCaptchaValidatedResult = "";
  isHuman: boolean = false;

  constructor() {
    super();
    this.model = new SolicitudProyecto();
  }
  onVerify() {
    this.isHuman = true;
  }

  get enableSearch() {
    return this.isRecaptchEnabled ? this.isHuman : true;
  }

  get unmaskedCedula() {
    return this.cedula.split("-").join("");
  }
  miviviendaURl = process.env.VUE_APP_MI_VIVIENDA_URL;
  gotoMivivienda() {
    window.location.href = process.env.VUE_APP_MI_VIVIENDA_URL;
  }

  get isRecaptchEnabled() {
    return this.recaptchaKey;
  }
  async getErros() {
    let errors = [];
    const isValid = await this.$validator.validateAll();
    if (!isValid) errors.push("Debe colocar una cédula para la busqueda");
    const isRobot = this.isRecaptchEnabled && !this.isHuman;
    if (isRobot) errors.push("Debe indicar que no es un robot");
    return errors;
  }

  async getSolicitude() {
    try {
      const errors = await this.getErros();
      if (errors.length) throw errors;
      this.model = new SolicitudProyecto();
      this.isLoading = true;
      const url = `${process.env.VUE_APP_API_URL}SolicitudProyecto/GetDetailsByCedula/${this.unmaskedCedula}`;
      const res = await axios.get(url);
      this.model = res.data;
    } catch (errors) {
      this.operationFailedErrors(errors);
    } finally {
      this.isLoading = false;
    }
  }
}
