












import { Component, Vue, Model, Emit } from "vue-property-decorator";

@Component
export default class UploadPDFSOnly extends Vue {
  @Model("change", { type: File, default: null }) file!: File | null;

  get viewFile() {
    if (this.file) return URL.createObjectURL(this.file);
    return;
  }

  loaded() {
    this.$emit("change", this.file);
  }

  @Emit("change")
  onChange(file: any) {
    console.log(file);
    return file;
  }
}
