import Vue from 'vue';
import BTableColumnFilter from '@/filters/b-table-column.filter';
import moment from 'moment';
import Helpers from '@/core/helpers';

Vue.filter('bTableColumnValue', BTableColumnFilter.Value)
Vue.filter('date', (value: string) => value ? moment(value).format('L') : Helpers.Filters.EmptyText())
Vue.filter('time', (value: string) => value ? moment(value).format('LT') : Helpers.Filters.EmptyText())
Vue.filter('bool', (value: boolean) => value ? 'Sí' : 'No')
Vue.filter('medidaTiempoEmbarazo', (value: string) => value == "0" ? 'dias' : value == "1" ? 'semanas' : value == "2" ? 'meses' : Helpers.Filters.EmptyText())
Vue.filter('timeAgo', (value: string) => value ? `${Helpers.Filters.FromNow(value)}` : Helpers.Filters.EmptyText())
Vue.filter('empty', (value: string) => value || Helpers.Filters.EmptyText())
Vue.filter('dots', (value: string, count: number = 20) => value ? `${Helpers.Filters.Dots(value, count)}` : Helpers.Filters.EmptyText())
Vue.filter('capitalize', (value: string) => value ? value.toUpperCase() : value);
Vue.filter("cedula", (value: string) =>!!value ? Helpers.Filters.Cedula(value) : Helpers.Filters.EmptyText());
Vue.filter("phone", (value: string) =>!!value ? Helpers.Filters.Phone(value) : Helpers.Filters.EmptyText());
Vue.filter("maritalStatus", (value: number) =>Helpers.Filters.MaritalStatus(value));


