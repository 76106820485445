





















































import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import BaseVue from "@/core";
import SolicitudProyecto from "@/models/solicitud-proyecto.model";
@Component({})
export default class SolicitudComprobanteComponent extends BaseVue {
  @Prop() public value!: SolicitudProyecto;
  gotoMivivienda() {
    window.location.href = process.env.VUE_APP_MI_VIVIENDA_URL;
  }
   created(){
     this.value;
   }
}
