











































































import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import BaseVue from "@/core";
import SolicitudMejoramientoVivienda from "@/models/solicitud-mejoramiento-vivienda.model";
@Component({})
export default class SolicitudMejoramientoViviendaComprobanteComponent extends BaseVue {
  @Prop() public value!: SolicitudMejoramientoVivienda;
  public title: string = "MIVHED";
  gotoMivivienda() {
    window.location.href = process.env.VUE_APP_MI_VIVIENDA_URL;
  }
}
