import { BTableColumnType } from '@/enums/b-table-column-type.enum';

export default class BTableColumn {
    public width?: string;
    public visible?: boolean;
    public numeric?: boolean;
    public centered?: boolean;
    public customKey?: string;
    public customSort?: Function;
    public meta:any;
    public customTemplate?: boolean;
    public templateSlot?: string;
    public order?: number;
    public type?: BTableColumnType;
    constructor(
        public field: string,
        public label?: string, public sortable: boolean = true) { 
        this.label = this.label || this.field;     
        this.visible = true;
    }
    public customFilter!: (value:any)=> any;
}
