import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ConsultaSolicitud from "@/views/ConsultaSolicitud.vue";
import MejoramientoVivienda from "@/views/MejoramientoVivienda.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import ConsultaNoDisponible from "@/views/ConsultaNoDisponible.vue";
import UploadCertificate from "@/views/UploadCertificate.vue";
import UploadFinancialDocuments from "@/views/UploadFinancialDocuments.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: PageNotFound,
  },
  {
    path: "/mejoramiento-de-vivienda",
    name: "mejoramiento-de-vivienda",
    component: MejoramientoVivienda,
  },
  {
    path: "/consulta-solicitud",
    name: "consulta-solicitud",
    component: ConsultaSolicitud,
    to: { query: { cedula: "" } },
  },
  {
    path: "/upload-financial-documents",
    name: "upload-financial-documents",
    component: UploadFinancialDocuments,
    to: { query: { cedula: "" } },
  },
  {
    path: "/SolicitudMejoramientoVivienda/upload/:guid/certificate",
    name: "upload-file",
    component: UploadCertificate,
  },
  { path: "*", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
