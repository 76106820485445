

































































































































































































































































import "./base-table.css";
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { Watch } from "vue-property-decorator";
import BaseHttpClient from "@/core/http-clients/base-http.client";
import BTableColumn from "@/models/base/b-table-column.model";
import BTableConfig from "@/core/b-table-config/b-table.config";
import { AxiosResponse, AxiosPromise } from "axios";
import ApiQueryOption from "@/models/base/api-query-option.model";
import PaginationHeader from "@/models/base/pagination-header.model";
import HttpStatusCode from "@/enums/http-status-code.enum";
import { RowTable } from "@/typings/b-row-table";
import { BTableColumnType } from "@/enums/b-table-column-type.enum";
import moment from "moment";
import BTableConfigPagination from "@/core/b-table-config/b-table-pagination.config";
import Helpers from "@/core/helpers";
import BaseVue from "@/core";
import BaseEntity from "@/models/base/base-entity.model";
@Component({})
export default class BaseTableComponent extends BaseVue {
  @Prop() public config!: BTableConfig<object>;
  public baseClient = new BaseHttpClient(this.config.controller);
  public isEditing = false;
  created() {
    if (this.config.enableDefaultColumns) this.insertDefaultColumns();

    this.config.reload = this.getData;
  }

  get client() {
    return this.baseClient || new BaseHttpClient(this.config.controller);
  }
  insertDefaultColumns() {
    // var id = new BTableColumn("id", "Id");
    //id.order = -1;
    let fecha = new BTableColumn("createdDate", "Fecha");
    fecha.type = BTableColumnType.DateTime;
    fecha.order = 200;
    let usuario = new BTableColumn("createdBy", "Usuario");
    usuario.order = 201;
    this.config.insertColumns(fecha, usuario);
  }
  mounted() {
    this.sortColumns();
    if (this.config.loadOnMount) this.getData();
  }
  getData(callback?: Function) {
    this.config.loading = true;
    this.client.get(
      this.config.resource || "",
      this.queryParams(),
      (response: AxiosResponse) => {
        let data = (response.data as any[]) || [];
        this.config.setRows(data);
        let xPagination = response.headers["x-pagination"]
          ? new PaginationHeader(response.headers["x-pagination"])
          : new PaginationHeader();
        if (this.config.pagination.backend)
          this.config.pagination.total = xPagination.totalCount;

        this.$emit("load-success", response, this.config);
        if (callback) callback(data);
        if(this.config.onLoad)
        this.config.onLoad(data);
      },
      (response: AxiosResponse) => {
        this.$emit("load-error", response, this.config);
      },
      (response: AxiosResponse) => {
        this.config.loading = false;
        this.$emit("load-finally", response, this.config);
      }
    );
  }
  add() {
    this.$emit("add");
    if (this.config.aggregating.add) {
      this.config.aggregating.add();
    } else {
      if (this.config.aggregating.isModal) {
        this.config.aggregating.modal.active = true;
      } else {
        this.$router.push(
          `${this.$route.path}/${this.config.aggregating.routeTo}`
        );
      }
    }
  }
  edit(id: number, row: object, props: RowTable<object>) {
    this.$emit("edit", id, row, props);
    this.isEditing = true;
    if (this.config.editing.edit) {
      this.config.editing.edit(id, row, props);
    } else {
      if (this.config.editing.isModal) {
        this.config.editing.modal.model = this.copy(row) as any;
        this.config.editing.modal.modelId = id;
        this.config.editing.modal.active = true;
      } else {
        this.$router.push(
          `${this.$router.currentRoute.path}/${this.config.editing.routeTo}/${id}`
        );
      }
    }
  }

  remove(id: number, row: object, props: RowTable<object>) {
    this.$emit("delete", id, row, props);
    if (this.config.removing.remove) {
      this.config.removing.remove(id, row, props);
    } else {
      if (this.config.removing.showConfirm) {
        this.config.removing.setupDefaultConfirm(() => this.sendRemove(id, row, props));
        this.$buefy.dialog.confirm(this.config.removing.confirm);
      } else {
        this.$router.push(
          `${this.$router.currentRoute.path}/${this.config.removing.routeTo}/${id}`
        );
      }
    }
  }
  async sendRemove(id: number, row: object, props: RowTable<object>) {
    try {
      await this.config.repository().delete(id);
      this.$emit("removed", id, row, props);
      this.operationSuccess(this.config.removing.successText);
      if (this.config.reload) this.config.reload();
    } catch (e) {
      this.operationFailed(e);
    }
  }

  async sendAdd(model: object) {
    try {
      var isValid = await this.$validator.validateAll();
      if (isValid) {
        this.config.aggregating.saving = true;
        let result = await this.config.repository().create(model);
        this.config.aggregating.saving = false;
        this.$emit("add", result);
        this.operationSuccess();

        if (this.config.aggregating.modal.closeOnSave) this.onModalClose();
        return;
      }

      throw this.$validator.errors.items.map(e => e.msg);
    } catch (errors) {
      this.operationFailedErrors(errors);
    }
  }
  async sendEdit(id: number, model: object) {
    try {
      var isValid = await this.$validator.validateAll();
      if (isValid) {
        this.config.editing.modal.saving = true;
        var result = await this.config.repository().update(id, model);
        this.config.editing.modal.saving = false;
        this.$emit("edit", result);
        this.operationSuccess();
        if (this.config.editing.modal.closeOnSave) this.onModalClose();
        return;
      }

      throw this.$validator.errors.items.map(e => e.msg);
    } catch (errors) {
      this.operationFailedErrors(errors);
    }
  }
  onModalRestart() {
    this.$emit("restart");
    if (this.isEditing) {
      if (this.config.editing.modal.restart)
        this.config.editing.modal.restart();
      this.config.editing.modal.model = new BaseEntity();
    } else {
      if (this.config.aggregating.modal.restart)
        this.config.aggregating.modal.restart();

      this.config.aggregating.modal.model = new BaseEntity();
    }
  }
  onModalClose() {
    if (this.isEditing) {
      this.config.editing.modal.active = false;
      this.$emit("modal-close");
      if (this.config.editing.modal.close) this.config.editing.modal.close();
    } else {
      this.config.aggregating.modal.active = false;

      this.$emit("modal-close");
      if (this.config.aggregating.modal.close)
        this.config.aggregating.modal.close();
    }
    this.isEditing = false;
  }

  onModalSave() {
    if (this.isEditing) {
      if (this.config.editing.modal.save) this.config.editing.modal.save();
      else {
        let model = this.copy(this.config.editing.modal.model);
        let id = this.config.editing.modal.modelId;
        this.sendEdit(id, model);
      }
      this.$emit("edited", this.config.editing.modal.model);
      if (this.config.editing.modal.closeOnSave) {
        this.onModalClose();
      }
    } else {
      this.$emit("added", this.config.aggregating.modal.model);
      if (this.config.aggregating.modal.save)
        this.config.aggregating.modal.save();
      else {
        let model = this.copy(this.config.aggregating.modal.model);
        this.sendAdd(model);
      }
      if (this.config.aggregating.modal.closeOnSave) {
        this.onModalClose();
      }
    }
    this.isEditing = false;
  }

  sortColumns() {
    this.config.columns = this.config.columns.sort(
      (a: BTableColumn, b: BTableColumn) => {
        if (a.order === undefined) a.order = this.config.columns.indexOf(a);

        if (b.order === undefined) b.order = this.config.columns.indexOf(b);

        return a.order - b.order;
      }
    );
  }
  getValueTime(row: any, value: string) {
    let val = this.getFieldValue(row, value);
    return Helpers.Filters.Time(val);
  }
  getValueDate(row: any, value: string) {
    let val = this.getFieldValue(row, value);
    return Helpers.Filters.Date(val);
  }
  //  Computed
  queryParams(): ApiQueryOption {
    let instace = new ApiQueryOption();
    instace.orderby = (this.config.sorting.order === "desc" ? "-" : "").concat(
      this.config.sorting.field
    );
    instace.page = this.config.pagination.currentPage;
    instace.perpage = this.config.pagination.perPage;
    instace.where = this.config.whereParams;
    instace.include = this.config.include;

    if (!instace.where) delete instace.where;
    if (!instace.include) delete instace.include;
    return instace;
  }

  get selectableColumn() {
    return this.config.selectable ? this.config.selection.selected : null;
  }
  set selectableColumn(object: any) {
    this.config.selection.selected = object;
  }

  //  WATCHES
  @Watch("config.columns", { deep: true })
  onColumnsChange(columns: any[]) {
    this.$emit("columns-change", columns);
  }
  @Watch("config.data", { deep: true })
  onRowsChange(data: any[]) {
    this.$emit("rows-change", data);
  }
  @Watch("config.pagination", { deep: true })
  onPaginationChange(value: BTableConfigPagination) {
    if (value.perPage) this.getData();
  }
  //  SELECTION
  public select(row: object, oldRow: object) {
    if (this.config.selectable === false) {
      this.config.selection.selected = null;
      return;
    }

    if (!this.config.selection.change) {
      this.$emit("select", row, oldRow);
    } else this.config.selection.change(row, oldRow);
  }

  //  DETAILS
  public openRow(row: object) {
    if (!this.config.detailing.open) {
      this.$emit("details-open", row);
    } else this.config.detailing.open(row);
  }
  public closeRow(row: object) {
    if (!this.config.detailing.close) {
      this.$emit("details-close", row);
    } else this.config.detailing.close(row);
  }

  //  PAGINATION
  public pageChange(page: number) {
    if (!this.config.pagination.change) {
      this.$emit("page-change", page);
      this.config.pagination.currentPage = page;
      if (this.config.paginated && this.config.pagination.backend)
        this.getData(this.config.pagination.onChanged);
    } else{
      this.config.pagination.change(page);
      if(this.config.pagination.onChanged)
      this.config.pagination.onChanged(this.config.data);
    };
  }

  //  SORTING
  public sort(field: string, order: string) {
    if (!this.config.sorting.change) {
      this.config.sorting.field = field;
      this.config.sorting.order = order;
      this.$emit("sort", field, order);

      if (this.config.sorting.backend) this.getData();
    } else this.config.sorting.change(field, order);
  }

  //  CHECKING
  public check(list: object[], row: object) {
    if (!this.config.checking.check) {
      this.$emit("check", list, row);
    } else this.config.checking.check(list, row);
  }
  public checkAll(list: object[]) {
    if (!this.config.checking.checkAll) {
      this.$emit("checkAll", list);
    } else this.config.checking.checkAll(list);
  }

  //  CLICKING
  public click(row: object) {
    if (!this.config.click) {
      this.$emit("click", row);
    } else this.config.click(row);
  }
  public dblclick(row: object) {
    if (!this.config.dblclick) {
      this.$emit("dblclick", row);
    } else this.config.dblclick(row);
  }
  public rightClick(row: object) {
    if (!this.config.rightClick) {
      this.$emit("rightClick", row);
    } else this.config.rightClick(row);
  }

  getFieldValue(row: any, field: string) {
    return Helpers.GetValueFromPath(row, field);
  }
}
