import BaseHttpClient from './base-http.client';
import ApiQueryOption from '@/models/base/api-query-option.model';

export default class BaseRepository<T> {
    private client: BaseHttpClient;
    constructor(public controller: string) {
        this.client = new BaseHttpClient(controller);
    }
    public async getById(id: number | string, params = new ApiQueryOption()): Promise<T>{
        try {
            return await this.client.getAsync(id.toString(), params);
        } catch (error) {
            throw error;
        }
    }
    public async get(params = new ApiQueryOption(), resource = ''): Promise<T[]> {
        try {
            return await this.client.getAsync<T[]>(resource, params)
        } catch (error) {
            throw error;
        }
    }
    public async create(entity: T, resource= '',params?: object): Promise<T> {
        try {
            return await this.client.postAsync<T>(resource, entity, params);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
    public async update(id: number, entity: T, resource = '',params?: object): Promise<T> {
        try {
            return this.client.putAsync<T>(resource, id, entity, params);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
    public async delete(id: number, resource='', params?: object): Promise<T> {
        try {
            return await this.client.deleteAsync<T>(resource, id, params);
        } catch (error) {
            throw error;
        }
    }
    public async first(where: string, include = '', resource = ''): Promise<T> {
        let options = new ApiQueryOption(where);
        options.perpage = 1;
        if (include)
            options.include = include
        let result = await this.get(options, resource);
        return result[0];
    }
    public async oneId(id: number, include= '', resource = ''): Promise<T> {
        return this.first(`id=${id}`, include,resource);
    }
    public async last(where: string, include= '', resource = ''): Promise<T> {
        let options = new ApiQueryOption(where);
        options.perpage = 1;
        if (include)
            options.include = include
        let result = await this.get(options, resource);
        return result[result.length-1];
    }
    public async where(where: string, include = '', resource = '', params = new ApiQueryOption()): Promise<T[]> {
        let options = params || new ApiQueryOption();
        options.where = where;
        if (include)
            options.include = include;

        return await this.get(options, resource);
    }
}
