
































































































































































































































import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import BaseVue from "@/core";
import Helpers from "@/core/helpers";
import Ciudadano from "@/models/ciudadano.model";
import { EstadoCivil } from "@/enums/sexo.enum";
import TipoTrabajo from "@/models/proyecto.model";
import CedulaSolicitudModel from "@/models/cedula-solicitante.model";
import BaseHttpClient from "@/core/http-clients/base-http.client";

@Component({})
export default class CiudadanoFormComponent extends BaseVue {
  @Prop() public value!: Ciudadano;
  @Prop() public esConyuge!: boolean;
  @Prop() public mejoramietoVivienda!: boolean;
  @Prop() public estadoCivil!: EstadoCivil;

  moneyOptions = {
    decimal: ".",
    thousands: ",",
    prefix: "RD$",
    precision: 0,
    masked: false,
  };
  model: Ciudadano = this.value ? this.copy(this.value) : new Ciudadano();
  tipoTrabajo: TipoTrabajo | null = null;
  solicitudProyectoRepository = new BaseHttpClient(this.mejoramietoVivienda? "SolicitudMejoramientoVivienda":"SolicitudProyecto");
  selectTipoTrabajo(value: TipoTrabajo, added: boolean = true) {
    if (added) {
      this.tipoTrabajo = value;
      this.model.tipoTrabajoNombre = value.otro ? "" : value.nombre;
    } else {
      this.model.tipoTrabajoNombre = "";
      this.tipoTrabajo = null;
    }
  }
  created() {
    if (this.esConyuge) this.model.estadoCivil = this.estadoCivil;
  }
  buildModel(model: Ciudadano) {
    let ingresoMensual = model.ingresoMensual as number;
    if (ingresoMensual)
      model.ingresoMensual = Number.parseInt(ingresoMensual.toString());
    return model;
  }

  isRealCedula(){
    const isValidCedula = Helpers.Validators.validarCedula(this.model.cedula)
    if(!isValidCedula && this.model.cedula!= "" ) return "Debes colocar una cedula real"
  }

  async validate() {
    return await this.$validator.validateAll();
  }
  @Watch("model", { deep: true })
  onModelChange(value: Ciudadano) {
    this.$emit("input", this.buildModel(value));
  }
  @Watch("estadoCivil")
  onEstadoCivilChange(value: EstadoCivil) {
    this.model.estadoCivil = value;
  }
  loadingCedulaInput = false;
  async getSolicitude() {
    if (!this.isCedula) return;
    try {
      this.loadingCedulaInput = true;
      let cedula = Helpers.FilterToValue.Cedula(this.model.cedula);
      let result = await this.solicitudProyectoRepository.getAsync<CedulaSolicitudModel>(
        "GetByCedula",
        { cedula: cedula }
      );
      this.$emit("checkSolicitude", result);
      return result;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingCedulaInput = false;
    }
  }
  get isCedula() {
    return Helpers.Validators.isCedula(this.model.cedula);
  }
}
