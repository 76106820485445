import BaseEntity from "@/models/base/base-entity.model";
import Ciudadano from "./ciudadano.model";
import Contacto from "./contacto.model";
import SolicitudProyectoValidacionRequisito from "./solicitudProyectoValidacionRequisito.model";
import Proyecto from "./tipo-trabajo.model";

export default class SolicitudProyecto extends BaseEntity {
  codigoSolicitud: string = "";
  codigoEstado: string = "";
  solicitanteId: number = 0;
  solicitanteCedula: string = "";
  desarrolladorId: number = 0;
  conyugeCedula: string = "";
  telefonoVivienda: string = "";
  cantidadPersonas?: number = undefined;
  comentarios: string = "";
  direccion: string = "";
  estadoId: number = 0;
  estadoNombre: string = "";
  proyectoId?: number = undefined;
  proyectoNombre: string = "";
  tipoViviendaId?: number = undefined;
  tipoViviendaNombre: string = "";
  sector?: string = undefined;
  provinciaId?: number = undefined;
  provinciaNombre: string = "";
  codigoProvincia: string = "";
  municipioId?: number = undefined;
  municipioNombre: string = "";
  codigoMunicipio: string = "";
  esInformacionVeridica: boolean = false;
  isPersonalAndCreditInfoAuthorized: boolean = false;
  solicitante = new Ciudadano();
  contacto = new Contacto();
  proyecto = new Proyecto();
  validacionRequisitos: SolicitudProyectoValidacionRequisito[] | null = null;
  solicitudMetadata: any = [];
  employmentLetterId: number | null = null;
  bankStatementId: number | null = null;
  constructor() {
    super();
    this.id = undefined;
  }
}
