import BaseEntity from "@/models/base/base-entity.model";

export default class Contacto extends BaseEntity {
  nombreDesarrollador: string = "";
  nombreContacto1: string = "";
  nombreContacto2: string = "";
  numeroContacto1: string = "";
  numeroContacto2: string = "";
  correoContacto1: string = "";
  correoContacto2: string = "";
  isDisabled: boolean = false;
  constructor() {
    super();
    this.id = undefined;
  }
}
