




































import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import BaseHttpClient from "@/core/http-clients/base-http.client";
import BTreeselectConfig, {
  TreeselectMethodParams,
  TreeselectModel
} from "@/core/b-treeselect-config/b-treeselect.config";
import ApiQueryOption from "@/models/base/api-query-option.model";
import {
  LOAD_CHILDREN_OPTIONS,
  ASYNC_SEARCH,
  LOAD_ROOT_OPTIONS
} from "@riophae/vue-treeselect";
import BaseEntity from "@/models/base/base-entity.model";

@Component({})
export default class BaseTreeSelect extends Vue {
  @Prop() public controller!: string;
  @Prop() public childController!: string;
  @Prop() public action!: string;
  @Prop() public childAction!: string;
  @Prop() public label!: string;
  @Prop() public include!: string;
  @Prop() public childInclude!: string;
  @Prop() public childLabel!: string;
  @Prop() public parentAttr!: string;
  @Prop() public checkChildrenAttr!: string;
  @Prop() public childrenController!: string;
  @Prop() public whereParams!: string;
  @Prop() public whereChildrenParams!: string;
  @Prop() public disableBranchNodes!: boolean;
  @Prop() public value: any;
  @Prop() public placeholder!: string;
  @Prop() public disabled!: boolean;
  @Prop() public multiple!: boolean;
  @Prop() public config!: BTreeselectConfig;

  model = this.config || new BTreeselectConfig();
  client!: BaseHttpClient;
  childClient!: BaseHttpClient;
  data: any[] = [];
  childData: any[] = [];
  options: TreeselectModel[] | null = null;
  constructor() {
    super();
    this.model.controller = this.controller;
    this.model.action = this.action;
    this.model.value = this.value;
    this.model.whereParams = this.whereParams;
    this.model.whereChildrenParams = this.whereChildrenParams;

    this.model.disabled = this.disabled;
    this.model.multiple = this.multiple;
    this.model.disableBranchNodes = this.disableBranchNodes;

    this.model.loadOptions = this.loadOptions;
    this.model.open = this.open;
    this.model.close = this.close;
    this.model.input = this.input;
    this.model.select = this.select;
    this.model.deselect = this.deselect;
    this.model.searchChange = this.searchChange;

    this.client = new BaseHttpClient(this.model.controller);
    this.childClient = new BaseHttpClient(
      this.childController || this.model.controller
    );
  }
  getOptions(data: any[]) {
    return data.map((item: any) => {
      var label = item[this.label];
      var options = new TreeselectModel(item.id, label);
      if (item[this.checkChildrenAttr]) options.children = null;
      return options;
    });
  }
  getChildOptions(data: any[]) {
    return data.map((item: any) => {
      var label = item[this.childLabel || this.label];
      var options = new TreeselectModel(item.id, label);
      if (item[this.checkChildrenAttr]) options.children = null;
      return options;
    });
  }
  public async loadOptions({
    action,
    parentNode,
    callback,
    instanceId,
    searchQuery
  }: TreeselectMethodParams) {
    switch (action) {
      case LOAD_ROOT_OPTIONS:
        await this.loadRootOptions(callback);
        break;
      case LOAD_CHILDREN_OPTIONS:
        await this.loadRootChildrenOptions(parentNode, callback);
        break;
      case ASYNC_SEARCH:
        await this.asyncSearch(searchQuery, callback);
        break;
    }
  }

  public async loadRootOptions(callback: Function) {
    try {
      let data = await this.client.getAsync<any[]>(
        this.model.action,
        this.queryParams
      );
      if (data && data.length > 0) {
        data.forEach(x => this.data.push(x));
        this.options = this.getOptions(data);
      } else {
        this.options = null;
        this.data = [];
      }
      callback();
    } catch (error) {
      this.data = [];
      console.log(error);
      callback(new Error(this.model.noChildrenText));
    }
  }

  public async loadRootChildrenOptions(
    parent: TreeselectModel,
    callback: Function
  ) {
    try {
      let data = (await this.childClient.getAsync(
        this.childAction || this.model.action,
        this.queryChildParam(parent.id)
      )) as any[];

      if (data && data.length > 0) {
        data.forEach(x => this.childData.push(x));
        parent.children = this.getChildOptions(data);
      } else {
        parent.children = [];
      }

      callback();
    } catch (error) {
      console.log(error);
      this.childData = [];
      callback(new Error(this.model.noChildrenText));
    }
  }
  public async asyncSearch(searchQuery: string | null, callback: Function) {
    try {
      this.data = await this.client.getAsync<any[]>(
        this.model.action,
        this.queryParams
      );
      if (this.data && this.data.length > 0)
        callback(null, this.getOptions(this.data));
      else callback(null, null);
    } catch (error) {
      this.data = [];
      console.log(error);
      callback(new Error(this.model.noChildrenText));
    }
  }
  public open(instanceId: string) {
    this.$emit("open", instanceId);
  }
  public close(
    value: number | number[] | TreeselectModel | TreeselectModel[],
    instanceId: string
  ) {
    this.$emit("close", value, this.data, this.options, instanceId);
  }
  public input(
    value: number | number[] | TreeselectModel | TreeselectModel[],
    instanceId: string
  ) {
    this.$emit("input", value, this.data, this.options, instanceId);
  }
  public select(value: TreeselectModel, instanceId: string) {
    let selected = null;
    if (this.disableBranchNodes)
      selected = this.childData.find(x => x.id === value.id);
    if (!selected) selected = this.data.find(x => x.id === value.id);

    this.$emit("select", value, selected, this.data, this.options, instanceId);
  }
  public deselect(value: TreeselectModel, instanceId: string) {
    let selected = this.disableBranchNodes
      ? this.childData.find(x => x.id === value.id)
      : this.data.find(x => x.id === value.id);
    this.$emit(
      "deselect",
      value,
      selected,
      this.data,
      this.options,
      instanceId
    );
  }
  public searchChange(query: string, instanceId: string) {
    this.$emit("searchChange", query, this.data, this.options, instanceId);
  }

  get queryParams(): ApiQueryOption {
    let where = this.model.whereParams || '';
    let instace = new ApiQueryOption(where);
    instace.orderby = "id";
    if (this.include) instace.include = this.include;
    if (!where) delete instace.where;
    return instace;
  }

  queryChildParam(parentId: string): ApiQueryOption {
    let where = `${this.parentAttr}=${parentId}`;
    let whereChild = this.model.whereChildrenParams;
    if (whereChild) where += `${whereChild}`;
    let instace = new ApiQueryOption(where);
    instace.orderby = "id";
    if (this.childInclude) instace.include = this.childInclude;
    if (!where) delete instace.where;
    return instace;
  }
}
