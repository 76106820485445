






























import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import GuidValidationModel from "@/models/guidValidationModel.model";
import HttpStatusCode from "@/enums/http-status-code.enum";
import BaseVue from "@/core";

@Component
export default class UploadView extends BaseVue {
  guidModel!: GuidValidationModel;
  formData = new FormData();
  public file: File | null = null;
  show: boolean;
  showUploadCertificate: boolean = false;
  loading: boolean;
  solicitudeId: number;
  showInvalid: boolean = false;
  constructor() {
    super();
    this.loading = false;
    this.show = false;
    this.guidModel = new GuidValidationModel();
    this.guidModel.guid = this.$route.params.guid;
    this.solicitudeId = 0;
  }

  showSuccess() {
    this.file = null;
    this.showUploadCertificate = !this.showUploadCertificate;
    this.show = true;
  }
  created() {
    this.validateGuid();
  }

  async validateGuid() {
    this.loading = true;
    let url = `${process.env.VUE_APP_API_URL}SolicitudMejoramientoVivienda/ValidateGuid`;
    try {
      await axios.post(url, this.guidModel);
      this.showUploadCertificate = true;
      this.getIdByGuid();
    } catch (error) {
      this.showInvalid = true;
    } finally {
      this.loading = false;
    }
  }
  async getIdByGuid() {
    let url = `${process.env.VUE_APP_API_URL}SolicitudMejoramientoVivienda/GetSolicitudMejoramientoByGuid/${this.$route.params.guid}`;
    let { data } = await axios.get(url);
    this.solicitudeId = data.solicitudMejoramientoViviendaId;
  }

  public async saveFile() {
    this.loading = true;
    let data: any;
    if (this.file == null) return null;
    this.formData.append("file", this.file);
    let url = `${process.env.VUE_APP_API_URL}SolicitudMejoramientoVivienda/upload/${this.solicitudeId}`;
    try {
      const result = await axios.post(url, this.formData, {
        headers: { "content-type": "multipart/form-data" },
      });
      data = result;
      this.showSuccess();
    } catch (error) {
      this.$buefy.toast.open({
        message: "Error al subir el certificado",
        duration: 1000,
        type: "is-danger",
        position: "is-top-right",
      });
    } finally {
      this.loading = false;
    }
  }
}
