





























































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import GenericAdd from "@/core/generics/add.generic";
import SolicitudMejoramientoVivienda from "@/models/solicitud-mejoramiento-vivienda.model";
import Provincia from "@/models/provincia.model";
import { EstadoCivil } from "@/enums/sexo.enum";
import CiudadanoFormComponent from "@/components/ciudadano/ciudadano-form.vue";
import Municipio from "@/models/municipio.model";
import TipoVivienda from "@/models/tipo-vivienda.model";
import Helpers from "@/core/helpers";
import CedulaSolicitudModel from "@/models/cedula-solicitante.model";
import VueRecaptcha from "vue-recaptcha";
import MaterialParedesVivienda from "@/models/material-paredes-vivienda.model";
import BaseRepository from "@/core/http-clients/base.repository";
import MaterialTechoVivienda from "@/models/material-techo-vivienda.model";
import MaterialPisoVivienda from "@/models/material-piso-vivienda.model";
import RazonDeterioroVivienda from "@/models/razon-deterioro-vivienda.model";
import RepararZonaVivienda from "@/models/reparar-zona-vivienda.model";
import SolicitudMejoramientoViviendaMaterialParedesVivienda from "@/models/solicitud-mejoramiento-vivienda-material-paredes-vivienda.model";
import SolicitudMejoramientoViviendaMaterialPisoVivienda from "@/models/solicitud-mejoramiento-vivienda-material-piso-vivienda.model";
import SolicitudMejoramientoViviendaMaterialTechoVivienda from "@/models/solicitud-mejoramiento-vivienda-material-techo-vivienda.model";
import SolicitudMejoramientoViviendaRazonDeterioroVivienda from "@/models/solicitud-mejoramiento-vivienda-razon-deterioro-vivienda.model";
import SolicitudMejoramientoViviendaRepararZonaVivienda from "@/models/solicitud-mejoramiento-vivienda-reparar-zona-vivienda.model";
@Component({ components: { VueRecaptcha } })
export default class MejoramientoVivienda extends GenericAdd<SolicitudMejoramientoVivienda> {
  constructor() {
    super();
    this.controller = "SolicitudMejoramientoVivienda";
    this.model = new SolicitudMejoramientoVivienda();
  }
  public title: string = "MIVHED";
  recaptchaKey = process.env.VUE_APP_RECAPTCHAKEY;
  solicitud: SolicitudMejoramientoVivienda | null = null;
  tipoVivienda: TipoVivienda | null = null;
  reCaptchaValidatedResult = "";

  async getMaterialParedesVivienda() {
    this.materialParedesViviendaOptions =
      await this.materialParedesViviendaRepo.get();
  }
  get isMaterialParedesViviendaOtroSelected() {
    return this.materialParedesViviendaSelected.some((x) => x.otro);
  }
  materialParedesViviendaRepo = new BaseRepository<MaterialParedesVivienda>(
    "MaterialParedesVivienda"
  );
  materialParedesViviendaOptions: MaterialParedesVivienda[] = [];
  materialParedesViviendaSelected: MaterialParedesVivienda[] = [];

  async getMaterialPisoVivienda() {
    this.materialPisoViviendaOptions =
      await this.materialPisoviViendaRepo.get();
  }
  get isMaterialPisoViviendaOtroSelected() {
    return this.materialPisoViviendaSelected.some((x) => x.otro);
  }
  materialPisoviViendaRepo = new BaseRepository<MaterialPisoVivienda>(
    "MaterialPisoVivienda"
  );
  materialPisoViviendaOptions: MaterialPisoVivienda[] = [];
  materialPisoViviendaSelected: MaterialPisoVivienda[] = [];

  async getMaterialTechoVivienda() {
    this.materialTechoViviendaOptions =
      await this.materialTechoViviendaRepo.get();
  }
  get isMaterialTechoViviendaOtroSelected() {
    return this.materialTechoViviendaSelected.some((x) => x.otro);
  }
  materialTechoViviendaRepo = new BaseRepository<MaterialTechoVivienda>(
    "MaterialTechoVivienda"
  );
  materialTechoViviendaOptions: MaterialTechoVivienda[] = [];
  materialTechoViviendaSelected: MaterialTechoVivienda[] = [];

  async getRazonDeterioroVivienda() {
    this.razonDeterioroViviendaOptions =
      await this.razonDeterioroViviendaRepo.get();
  }
  get isRazonDeterioroViviendaOtroSelected() {
    return this.razonDeterioroViviendaSelected.some((x) => x.otro);
  }
  razonDeterioroViviendaRepo = new BaseRepository<RazonDeterioroVivienda>(
    "RazonDeterioroVivienda"
  );
  razonDeterioroViviendaOptions: RazonDeterioroVivienda[] = [];
  razonDeterioroViviendaSelected: RazonDeterioroVivienda[] = [];

  async getRepararZonaVivienda() {
    this.repararZonaViviendaOptions = await this.repararZonaViviendaRepo.get();
  }
  get isRepararZonaViviendaOtroSelected() {
    return this.repararZonaViviendaSelected.some((x) => x.otro);
  }
  repararZonaViviendaRepo = new BaseRepository<RepararZonaVivienda>(
    "RepararZonaVivienda"
  );
  repararZonaViviendaOptions: RepararZonaVivienda[] = [];
  repararZonaViviendaSelected: RepararZonaVivienda[] = [];

  async mounted() {
    this.getMaterialParedesVivienda();
    this.getMaterialPisoVivienda();
    this.getMaterialTechoVivienda();
    this.getRazonDeterioroVivienda();
    this.getRepararZonaVivienda();
  }

  get isRecaptchEnabled() {
    return false// !!this.recaptchaKey;
  }
  get tieneConyuge() {
    const estadoCivil = this.model.solicitante.estadoCivil;
    return (
      estadoCivil === EstadoCivil.CASADO ||
      estadoCivil === EstadoCivil.UNION_LIBRE
    );
  }

  selectTipoVivienda(value: TipoVivienda, added: boolean = true) {
    if (added) {
      this.tipoVivienda = value;
      this.model.tipoViviendaNombre = value.otro ? "" : value.nombre;
    } else {
      this.model.tipoViviendaNombre = "";
      this.tipoVivienda = null;
    }
  }
  selectProvincia(value: Provincia, added: boolean = true) {
    if (added) {
      this.model.provinciaId = value.id;
      this.model.provinciaNombre = value.nombre;
      this.model.codigoProvincia = value.codigo;
    } else {
      this.model.provinciaId = undefined;
      this.model.provinciaNombre = "";
      this.model.codigoProvincia = "";
    }
  }
  selectMunicipio(value: Municipio, added: boolean = true) {
    if (added) {
      this.model.municipioId = value.id;
      this.model.municipioNombre = value.nombre;
      this.model.codigoMunicipio = value.codigo;
    } else {
      this.model.municipioId = undefined;
      this.model.municipioNombre = "";
      this.model.codigoMunicipio = "";
    }
  }
  mapInfoVivienda(){
    this.model.materialParedesViviendas =
      this.materialParedesViviendaSelected.map(
        (x) => new SolicitudMejoramientoViviendaMaterialParedesVivienda(x.id, x.nombre)
      );
    if (!this.isMaterialParedesViviendaOtroSelected)
      this.model.materialParedesViviendaOtro = "";

    this.model.materialPisoViviendas = this.materialPisoViviendaSelected.map(
      (x) => new SolicitudMejoramientoViviendaMaterialPisoVivienda(x.id, x.nombre)
    );
    if (!this.isMaterialPisoViviendaOtroSelected)
      this.model.materialPisoViviendaOtro = "";

    this.model.materialTechoViviendas = this.materialTechoViviendaSelected.map(
      (x) => new SolicitudMejoramientoViviendaMaterialTechoVivienda(x.id, x.nombre)
    );
    if (!this.isMaterialTechoViviendaOtroSelected)
      this.model.materialTechoViviendaOtro = "";

    this.model.razonDeterioroViviendas =
      this.razonDeterioroViviendaSelected.map(
        (x) => new SolicitudMejoramientoViviendaRazonDeterioroVivienda(x.id, x.nombre)
      );
    if (!this.isRazonDeterioroViviendaOtroSelected)
      this.model.razonDeterioroViviendaOtro = "";

    this.model.repararZonaViviendas = this.repararZonaViviendaSelected.map(
      (x) => new SolicitudMejoramientoViviendaRepararZonaVivienda(x.id, x.nombre)
    );
    if (!this.isRepararZonaViviendaOtroSelected)
      this.model.repararZonaViviendaOtro = "";
  }

  async beforeLeaveFirst() {
    const isValid = await this.isValid();
    if (!this.tieneConyuge) this.model.solicitante.conyuge = null;
    this.mapInfoVivienda();
    return isValid;
  }

  protected async isValid() {
    try {
      let errors = [];
      const isModelValid = await this.$validator.validateAll();
      let isSolicitanteValid = await this.validateSoliciante();
      let isFormValid = isModelValid && isSolicitanteValid;

      if (!isFormValid)
        errors.push("Hay datos inválidos en la información de la solicitud");

      if (this.tieneConyuge) {
        let isConyugeValid = await this.validateConyuge();
        if (!isConyugeValid)
          errors.push("Hay datos inválidos en la información del cónyuge");
      }

      if (this.model.solicitante.cedula) {
        const isValidCedula = Helpers.Validators.IsRealCedula(
          this.model.solicitante.cedula
        );
        if (!isValidCedula)
          errors.push("El solicitante debe ingresar una cédula real");
      }

      if (this.model.solicitante.conyuge) {
        const solicitanteCedula = Helpers.Filters.Cedula(
          this.model.solicitante.cedula
        );
        const conyegueCedula = Helpers.Filters.Cedula(
          this.model.solicitante.conyuge.cedula
        );
        const isValidCedula = Helpers.Validators.IsRealCedula(
          this.model.solicitante.conyuge.cedula
        );
        if (solicitanteCedula === conyegueCedula)
          errors.push(
            "Las cédulas del solicitante y del cónyuge no pueden ser iguales"
          );
        if (!isValidCedula)
          errors.push("El conyugue debe ingresar una cédula real");
      }

      if (errors.length) throw errors;

      const solicitudeSolicitante = await this.getSolicitanteSolicitud();
      if (solicitudeSolicitante)
        isFormValid = isFormValid && !solicitudeSolicitante.codigoSolicitud;

      if (this.tieneConyuge) {
        const solicitudeConyuge = await this.getConyugeSolicitud();
        if (solicitudeConyuge)
          isFormValid = isFormValid && !solicitudeConyuge.codigoSolicitud;
      }

      if (!isFormValid) throw errors;

      return isFormValid;
    } catch (errors) {
      this.operationFailedErrors(errors);
    }
  }
  async getSolicitanteSolicitud() {
    let ciudadanoComponent = this.$refs["ciudadano"] as CiudadanoFormComponent;
    let solicitude = await ciudadanoComponent.getSolicitude();
    return solicitude;
  }
  async getConyugeSolicitud() {
    let conyugeComponent = this.$refs["conyuge"] as CiudadanoFormComponent;
    let solicitude = await conyugeComponent.getSolicitude();
    return solicitude;
  }
  async validateSoliciante() {
    let ciudadanoComponent = this.$refs["ciudadano"] as CiudadanoFormComponent;
    return await ciudadanoComponent.validate();
  }
  async validateConyuge() {
    let conyugeComponent = this.$refs["conyuge"] as CiudadanoFormComponent;
    return await conyugeComponent.validate();
  }
  buldModel(model: SolicitudMejoramientoVivienda) {
    let cantidadPersonas = model.cantidadPersonas as number;
    model.cantidadPersonas = Number.parseInt(cantidadPersonas.toString());
    model.solicitante.cedula = model.solicitanteCedula =
      Helpers.FilterToValue.Cedula(model.solicitante.cedula);
    model.solicitante.celular = Helpers.FilterToValue.Phone(
      model.solicitante.celular
    );
    model.telefonoVivienda = Helpers.FilterToValue.Phone(
      model.telefonoVivienda
    );

    if (model.solicitante.conyuge) {
      model.solicitante.conyuge.cedula = model.conyugeCedula =
        Helpers.FilterToValue.Cedula(model.solicitante.conyuge.cedula);
      model.solicitante.conyuge.celular = Helpers.FilterToValue.Phone(
        model.solicitante.conyuge.celular
      );
    }
    if (!this.tieneConyuge) {
      model.solicitante.conyuge = null;
      model.conyugeCedula = "";
    }

    

    return model;
  }
  async beforeLeaveSecond() {
    if (this.solicitud) return true;
    let errors = [];
    if (!this.model.esInformacionVeridica)
      errors.push("Debe indicar que la información suministrada es válida");

    if (!this.wasreCaptchaValidated)
      errors.push("Debe indicar que no es un robot");

    if (errors.length > 0) {
      this.operationFailedErrors(errors, false, 5000);
      return false;
    }
    try {
      this.saving = true;
      const val = this.buldModel(this.model);
      this.solicitud = await this.repository.create(val);
      this.model.id = this.solicitud.id;
      this.model.createdDate = this.solicitud.createdDate;
      this.model.codigoSolicitud = this.solicitud.codigoSolicitud;
      this.operationSuccess("Datos enviados", 5000);
      return true;
    } catch (error) {
      if (error && error.data && Array.isArray(error.data.errors)) {
        error.data.errors.forEach((element: string) => {
          this.operationFailed(element, 5000);
        });
      }
      return false;
    } finally {
      this.saving = false;
    }
  }

  checkSolicitude(solicitude: CedulaSolicitudModel) {
    if (solicitude.codigoSolicitud) {
      const cedula = Helpers.Filters.Cedula(solicitude.cedula);
      this.operationFailed(
        `El ciudadano con la cédula ${cedula} ya realizó una solicitud - Código de solicitud: ${solicitude.codigoSolicitud}`
      );
    }
  }
  get wasreCaptchaValidated() {
    if (!this.isRecaptchEnabled) return true;
    return this.reCaptchaValidatedResult.length > 0;
  }
  isSuccefully(recaptchaResult: string) {
    this.reCaptchaValidatedResult = recaptchaResult;
  }
  gotoMivivienda() {
    window.location.href = process.env.VUE_APP_MI_VIVIENDA_URL;
  }
}
