







































































































































import BaseVue from "@/core";
import { Component, Vue, Watch } from "vue-property-decorator";
import SolicitudProyecto from "@/models/solicitud-proyecto.model";
import SolicitudInvalida from "../components/solicitud/solicitud-invalida.vue";
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
import IEntityValidationResult from "@/core/interfaces/entity-validation-result.interface";
import Helpers from "@/core/helpers";
import BarraEstadoSolicitud from "@/components/barra-estado-solicitud.vue";
import { CodigoEstadoSolicitud } from "@/enums/codigo-estado-solicitud";
import UploadPDFSOnly from "@/components/Upload/UploadPDFSOnly.vue";
@Component({
  components: {
    SolicitudInvalida,
    VueRecaptcha,
    UploadPDFSOnly,
  },
})
export default class UploadFinancialDocuments extends BaseVue {
  recaptchaKey = process.env.VUE_APP_RECAPTCHAKEY;
  cedula: string = String(this.$route.query.cedula) || "";
  public model: SolicitudProyecto | null;
  isLoading: boolean = false;
  reCaptchaValidatedResult = "";
  isHuman: boolean = false;
  evaluacionFinanciera = CodigoEstadoSolicitud.EVALUACION_FINANCIERA;
  formData = new FormData();
  public employmentLetter: File | null = null;
  public bankStatement: File | null = null;
  loading: boolean = false;
  show: boolean = false;
  showUploadCertificate = true;
  constructor() {
    super();
    this.model = new SolicitudProyecto();
  }
  onVerify() {
    this.isHuman = true;
  }
  showSuccess() {
    this.employmentLetter = null;
    this.bankStatement = null;
    this.showUploadCertificate = false;
    this.show = true;
  }

  validateFileSize() {}

  get enableSearch() {
    return this.isRecaptchEnabled ? this.isHuman : true;
  }

  get unmaskedCedula() {
    return this.cedula.split("-").join("");
  }
  miviviendaURl = process.env.VUE_APP_MI_VIVIENDA_URL;
  gotoMivivienda() {
    window.location.href = process.env.VUE_APP_MI_VIVIENDA_URL;
  }

  get isRecaptchEnabled() {
    return this.recaptchaKey;
  }
  async getErros() {
    let errors = [];
    const isValid = await this.$validator.validateAll();
    if (!isValid) errors.push("Debe colocar una cédula para la busqueda");
    const isRobot = this.isRecaptchEnabled && !this.isHuman;
    if (isRobot) errors.push("Debe indicar que no es un robot");
    return errors;
  }

  async getSolicitude() {
    try {
      const errors = await this.getErros();
      if (errors.length) throw errors;
      this.model = new SolicitudProyecto();
      this.isLoading = true;
      const url = `${process.env.VUE_APP_API_URL}SolicitudProyecto/GetDetailsByCedula/${this.unmaskedCedula}`;
      const res = await axios.get(url);
      this.model = res.data;
    } catch (errors) {
      this.operationFailedErrors(errors);
    } finally {
      this.isLoading = false;
    }
  }

  public async saveFile() {
    this.loading = true;
    let data: any;
    if (this.bankStatement === null || this.employmentLetter === null)
      return null;

    if (
      this.bankStatement.size > 5242880 ||
      this.employmentLetter.size > 5242880
    ) {
      this.loading = false;
      this.$buefy.toast.open({
        message: "El tamaño de los archivos no puede ser mayor a 5MB",
        type: "is-danger",
      });
      return null;
    }

    if (
      this.bankStatement.type !== "application/pdf" ||
      this.employmentLetter.type !== "application/pdf"
    ) {
      this.loading = false;
      this.$buefy.toast.open({
        message: "El tipo de archivo debe ser PDF",
        type: "is-danger",
      });
      return null;
    }

    this.formData.append("employmentLetter", this.employmentLetter);
    this.formData.append("bankStatement", this.bankStatement);
    let url = `${process.env.VUE_APP_API_URL}SolicitudProyecto/${
      this.model!.id
    }/UploadFinancialFiles`;
    try {
      const result = await axios.post(url, this.formData, {
        headers: { "content-type": "multipart/form-data" },
      });
      data = result;
      this.showSuccess();
    } catch (error) {
      this.$buefy.toast.open({
        message: "Error al subir los archivos",
        duration: 1000,
        type: "is-danger",
        position: "is-top-right",
      });
    } finally {
      this.loading = false;
    }
  }
}
