



































































import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import moment from "moment";
import BaseVue from "@/core";
import Helpers from "@/core/helpers";
import Ciudadano from "@/models/ciudadano.model";
import TipoTrabajo from "@/models/proyecto.model";
@Component({})
export default class CiudadanoFormDetailComponent extends BaseVue {
  @Prop() public value!: Ciudadano;
  @Prop() public tipoTrabajo!: TipoTrabajo;
}
