import BaseEntity from "@/models/base/base-entity.model";

export default class SolicitudMejoramientoViviendaRepararZonaVivienda extends BaseEntity {
  solicitudId: number = 0;
  repararZonaViviendaId: number = 0;
  zona: string = "";
  constructor(zonaId:number = 0,zona = '') {
    super();
    this.id = undefined;
    this.repararZonaViviendaId = zonaId;
    this.zona = zona;
  }
}
