import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import './assets/scss/app.scss';
import './assets/css/app.css';
import './assets/css/materialdesignicons.min.css';
import 'bulma-badge/dist/css/bulma-badge.min.css';

import './globals/plugins.global';
import './globals/components.global';
import './globals/filters.global';
import './globals/directives.global';

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
