import BaseEntity from "@/models/base/base-entity.model";

export default class SolicitudMejoramientoViviendaMaterialTechoVivienda extends BaseEntity {
  solicitudId: number = 0;
  materialTechoViviendaId: number = 0;
  material: string = "";
  constructor(materialId:number = 0,material = '') {
    super();
    this.id = undefined;
    this.materialTechoViviendaId = materialId;
    this.material = material;
  }
}
