













































































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import BaseVue from "@/core";

import SolicitudProyecto from "@/models/solicitud-proyecto.model";
import SolicitudComprobanteComponent from "../components/solicitud/solicitud-comprobante.vue";
import { CodigoEstadoSolicitud } from "@/enums/codigo-estado-solicitud";
import MetadataInfo from "@/components/solicitud/metadata-info.vue";
import Axios from "axios";
import { RequisitoSolicitud } from "@/enums/requisito-solicitud";
import BSteps from "@/components/stepper.vue";
import MensajeBienvenidaComponent from "@/components/contacto/mensaje-bienvenida.vue";
import ProyectoDisponible from "./solicitud/proyecto-disponible.vue";
import MensajeNoSubioDocumentos from "./solicitud/mensaje-no-subio-documentos.vue";
@Component({
  components: {
    SolicitudComprobanteComponent,
    MetadataInfo,
    "base-steps": BSteps,
    "mensaje-bienvenida": MensajeBienvenidaComponent,
    "proyecto-disponible": ProyectoDisponible,
    "mensaje-no-subio-documentos": MensajeNoSubioDocumentos,
  },
})
export default class BarraEstadoSolicitud extends BaseVue {
  @Prop() public solicitudProyecto!: SolicitudProyecto;
  public model!: SolicitudProyecto;
  evaluacionFinanciera = CodigoEstadoSolicitud.EVALUACION_FINANCIERA;
  noCalifica = CodigoEstadoSolicitud.NO_CALIFICA;
  noSubioDocumentos =
    CodigoEstadoSolicitud.NO_SUBIO_DOCUMENTOS_EVALUACION_FINANCIERA;
  public statusEvaluacionFinanciera: number = 2;
  public title: string = "MIVED";
  public bonoAsignadoStatus: string =
    "Bono asignado, beneficiario programa Mi Vivienda";
  requisitoSolicitud = RequisitoSolicitud;
  constructor() {
    super();
    this.model = Object.assign({}, this.solicitudProyecto);
    if (
      this.solicitudProyecto.codigoEstado ===
      CodigoEstadoSolicitud.SELECCION_BENEFICIARIOS
    )
      this.solicitudProyecto.estadoNombre = this.bonoAsignadoStatus;
  }
  async sendToEvaluacionFinanciera() {
    await Axios.put(
      `${process.env.VUE_APP_API_URL}SolicitudProyecto/UpdateStateSolicitudByStatusCode/${this.solicitudProyecto.id}/${CodigoEstadoSolicitud.EVALUACION_FINANCIERA}`
    ).then((response) => {
      if (response) {
        this.model = response.data.entity;
        this.solicitudProyecto.codigoEstado ===
          CodigoEstadoSolicitud.EVALUACION_FINANCIERA;
      }
    });
  }
  get requisitosCumple() {
    if (this.model.validacionRequisitos)
      return this.model.validacionRequisitos.filter((x) => x.estado === 0);

    return [];
  }
  get requisitosNoCumple() {
    if (this.model.validacionRequisitos)
      return this.model.validacionRequisitos.filter((x) => x.estado === 1);

    return [];
  }
  cumpleRequisito(value: RequisitoSolicitud) {
    return this.requisitosCumple.some((x) => {
      if (x.requisito) return x.requisito.requisito === value;
      return false;
    });
  }

  get esBeneficiarioSiuben() {
    return this.cumpleRequisito(RequisitoSolicitud.SER_BENEFICIARIO_SIUBEN);
  }

  get noTieneInmueble() {
    return this.cumpleRequisito(RequisitoSolicitud.NO_TENER_CASA_PROPIA);
  }

  get cumpleRangoIngresos() {
    return this.cumpleRequisito(RequisitoSolicitud.CUMPLIR_RANGO_INGRESOS);
  }

  get noEsBeneficiarioINVI() {
    return this.cumpleRequisito(RequisitoSolicitud.EXISTENCIA_INVI);
  }
  get isSolicitudEstadoHabilitado() {
    return (
      this.solicitudProyecto.codigoEstado === CodigoEstadoSolicitud.HABILITADO
    );
  }

  get isSolicitudEstadoDeshabilitado() {
    return this.model.codigoEstado === CodigoEstadoSolicitud.NO_HABILITADO;
  }

  get getActiveStep() {
    // if (
    //   !this.solicitudProyecto.validacionRequisitos ||
    //   this.solicitudProyecto.validacionRequisitos.length === 0
    // )
    //   return 0;
    const estado = this.model.codigoEstado;

    if (
      estado === CodigoEstadoSolicitud.RECIBIDA_CORRECTAMENTE ||
      estado === CodigoEstadoSolicitud.VALIDACION_DATOS
    )
      return 0;

    if (
      estado === CodigoEstadoSolicitud.HABILITADO ||
      estado === CodigoEstadoSolicitud.NO_HABILITADO
    )
      return 1;

    if (
      estado === CodigoEstadoSolicitud.EVALUACION_FINANCIERA ||
      estado === CodigoEstadoSolicitud.NO_CALIFICA ||
      estado === CodigoEstadoSolicitud.NO_SUBIO_DOCUMENTOS_EVALUACION_FINANCIERA
    )
      return 2;

    return 3;
  }
}
