export default class PaginationHeader {
    public currentPage: number;
    public pageSize: number;
    public totalCount: number;
    public totalPages: number;
    public previousPageLink: string;
    public nextPageLink: string;
    constructor(jsonValue?: string) {
        if (jsonValue)
        {
            var result = JSON.parse(jsonValue) as PaginationHeader;
            this.currentPage = result.currentPage;
            this.pageSize = result.pageSize;
            this.totalCount = result.totalCount;
            this.totalPages = result.totalPages;
            this.previousPageLink = result.previousPageLink;
            this.nextPageLink = result.nextPageLink;
        }else{
            this.currentPage = 1;
            this.pageSize = 0;
            this.totalCount = 0;
            this.totalPages = 0;
            this.previousPageLink = '';
            this.nextPageLink = '';
        }

    }
}
