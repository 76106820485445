






import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import BaseVue from "@/core";
@Component({})
export default class SolicitudInvalida extends BaseVue {
  
}
