import BaseEntity from "@/models/base/base-entity.model";

export default class SolicitudMejoramientoViviendaRazonDeterioroVivienda extends BaseEntity {
  solicitudId: number = 0;
  razonDeterioroViviendaId: number = 0;
  razon: string = "";
  constructor(razonId:number = 0,razon = '') {
    super();
    this.id = undefined;
    this.razonDeterioroViviendaId = razonId;
    this.razon = razon;
  }
}
