






















import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import moment from "moment";
import BaseVue from '@/core';
import Helpers from '@/core/helpers';
@Component({})
export default class DatePickerComponent extends BaseVue {
  @Prop() public placeholder: any;
  @Prop() public value: any;
  @Prop() public maxDate: any;
  @Prop() public minDate: any;
  @Prop() label!: string;
  private fechaSelected: any = null;
  max_date = this.maxDate || null;
  private months = Helpers.MonthNames;
  private days = Helpers.DayNames;

  dateFormat(dt: any) {
    if (dt) return Helpers.Filters.Date(dt);
  }

  @Watch("fechaSelected", { immediate: true })
  onFechaSelectedChange(val:any) {
    if(val)
    this.$emit("input", val);
  }
  
  @Watch("value", { immediate: true })
  onValueChange(val:any) {
    if (!val) this.fechaSelected = null;
    else this.fechaSelected = val;
  }
}
