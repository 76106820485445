import Vue from 'vue';
//import quickview from '@/components/shared/quickview/quickview.vue';

//Vue.component('quickview', quickview);

//base components
import baseTable from '@/components/base/base-table/base-table.vue';
import baseSelect from '@/components/base/base-select/base-select.vue';
import baseTreeselect from '@/components/base/base-tree-select/base-tree-select.vue';
import datePicker from "@/components/base/base-date-picker/base-date-picker.vue";
import ciudadanoForm from "@/components/ciudadano/ciudadano-form.vue";
import ciudadanoFormDetail from "@/components/ciudadano/ciudadano-form-detail.vue";
import solicitudFormDetail from "@/components/solicitud/solicitud-form-detail.vue";
import solicitudFormMejoramientoViviendaDetail from "@/components/solicitud/solicitud-form-mejoramiento-vivienda.vue";
import solicitudComprobante from "@/components/solicitud/solicitud-comprobante.vue";
import solicitudComprobanteMejoramientoVivienda from "@/components/solicitud/solicitud-comprobante-mejoramiento-vivienda.vue";
import barraEstadoSolicitud from "@/components/barra-estado-solicitud.vue"
import collapseDetalleSolicitud from "@/components/solicitud/collapse-detalle-solicitud.vue"
import comprobanteSolicitudSimple from "@/components/solicitud/comprobante-solicitud-simple.vue"
import UploadFile from "@/components/Upload/UploadComponent.vue"
import SuccessComponent from "@/components/Upload/successComponent.vue"
import InvalidLink from "@/components/InvalidLink.vue"
import MensajeNoCalifica from "@/components/solicitud/mensaje-no-califica.vue"

Vue.component('baseTable', baseTable);
Vue.component('baseSelect', baseSelect);
Vue.component('baseTreeSelect', baseTreeselect);
Vue.component('datePicker', datePicker);
Vue.component("ciudadanoForm", ciudadanoForm);
Vue.component("ciudadanoFormDetail", ciudadanoFormDetail);
Vue.component("solicitudFormDetail", solicitudFormDetail);
Vue.component("solicitudFormMejoramientoViviendaDetail", solicitudFormMejoramientoViviendaDetail);
Vue.component("solicitudComprobante", solicitudComprobante);
Vue.component("solicitudComprobanteMejoramientoVivienda", solicitudComprobanteMejoramientoVivienda);
Vue.component("barraEstadoSolicitud", barraEstadoSolicitud);
Vue.component("collapseDetalleSolicitud", collapseDetalleSolicitud);
Vue.component("comprobanteSolicitudSimple", comprobanteSolicitudSimple);
Vue.component("uploadFile", UploadFile);
Vue.component("successComponent", SuccessComponent);
Vue.component("invalid-link",InvalidLink)
Vue.component("mensajeNoCalifica", MensajeNoCalifica)
//Third party components
//Vue multiselect
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
Vue.component('multiselect', Multiselect)

//Tree select
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
Vue.component('treeselect', Treeselect)