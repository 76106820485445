import BaseEntity from "@/models/base/base-entity.model";
import TipoTrabajo from "./proyecto.model";

export default class Proyecto extends BaseEntity {
                 nombre: string = "";
                 otro: boolean = false;
                 isDisabled: boolean = false;
                 tipoProyectoId: number | null = null;
                 tipoProyecto: TipoTrabajo | null = null;
                 fechaApertura?: Date;
                 fechaCierre?: Date;
                 disponibilidad : boolean = true;
                 constructor() {
                   super();
                   this.id = undefined;
                 }
               }
