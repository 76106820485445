


























import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import BaseModel from "@/models/base/base.model";
import BaseApi from "@/core/http-clients/base.api";
import { AxiosResponse } from "axios";

@Component({})
export default class BaseSelectComponent extends Vue {
  @Prop() public label!: string;
  @Prop() public controller!: string;
  @Prop() public action!: string;
  @Prop() public placeholder!: string;
  @Prop() public excludeId!: number;
  @Prop() public disabled!: boolean;
  @Prop() public loadOnMount!: boolean;
  @Prop() public loadWhen!: boolean;
  @Prop() public value: any;
  @Prop() public initOnDisable!: boolean;
  @Prop() public nameValueCode!: string;
  @Prop() public options!: Array<any>;
  @Prop() public hideSelected!: boolean;
  @Prop() public dependencyKey!: string;
  @Prop() public dependecyValue!: string;
  @Prop() public openDirection!: string;
  @Prop() public notLoadDefault!: string;
  @Prop() public query!:object;

  @Prop() public allowEmpty!: boolean;

  get sAction() {
    return this.action ? "/" + this.action : '';
  }
  selected: BaseModel | any = null;
  data: BaseModel[] = [];
  loading: boolean = false;
  sLoadOnMount = this.loadOnMount === false ? false : true;
  sAllowEmpty = this.allowEmpty === false ? false : true;
  nombre = '';
  get sPlaceholder() {
    if (this.selected && this.selected.id) {
      return this.selected[this.label];
    } else {
      return this.placeholder || this.nombre || "Seleccione";
    }
  }

  async getAll(value?: any) {
    if (!this.options) {
      this.loading = true;

      let url = `${this.controller}${this.sAction}`;

      if (this.nameValueCode)
        url = "NameValues/" + this.nameValueCode + "/children";

      let baseApi = new BaseApi(url);
      if (this.excludeId) {
        baseApi.apiUrl += `?where=id!=${this.excludeId}`;
      }
      if (this.dependecyValue) {
        baseApi.apiUrl += `?$filter=${this.dependencyKey} eq '${this.dependecyValue}'`;
      }

      await baseApi.request(
        "get",
        '',
        null,
        this.query || {},
        (response: AxiosResponse) => {
          let data = response.data as BaseModel[];
          this.data = data;
          this.processData(response, value);
        },
        (response: AxiosResponse) => {
          console.log(response);
          this.loading = false;

          this.$emit("load-error", response);
        },
        (response: AxiosResponse) => {
          this.$emit("load-finally", response);
        }
      );
    } else {
      this.data = this.options;
      this.processData(null, value);
    }
  }

  processData(response?: any, value?: any) {
    if(this.options && !this.options.length){
      return;
    }
    this.loading = false;

    this.data.forEach(x => {
      if (x.isDefault && !this.notLoadDefault) {
        this.selected = x;
        this.$emit("input", this.selected.id);
        this.$emit("select", this.selected);
      }
    });

    if (value) {
      var filter = this.data.filter(item => item.id == value);
      this.selected = filter.length > 0 ? filter[0] : new BaseModel();
      this.$emit("input", this.selected.id);
      this.$emit("select", this.selected);
    }
    this.$emit("onLoad", this.data);
    this.$emit("load-success", response, this.data);
  }

  @Watch("value", { immediate: true })
  async onInput(value: number) {
    if (value) {
      if (this.data.length) {
        var filter = this.data.filter(item => item.id == value);
        this.selected = filter.length > 0 ? filter[0] : null;
        this.$emit("input", this.selected.id);
        this.$emit("select", this.selected);
      } else {
        await this.getAll(value);
      }
    } else {
      this.selected = null;
    }
  }

  @Watch("dependecyValue")
  onDependency(value: number) {
    if (!value) {
      this.selected = null;
      this.$emit("input", null);
      this.$emit("remove", null);
    } else {
      this.getAll(this.value);
    }
  }
  searchChange(text: string) {
    this.nombre = text;
    this.$emit("searchChange", text);
  }
  onSelected(selected: BaseModel) {
    this.$emit("input", selected.id);
    this.$emit("select", selected);
    this.$emit("onChange", selected);
  }
  onRemove(selected: BaseModel) {
    this.$emit("input", null);
    this.$emit("remove", selected);
    this.selected = null;
  }
  onOpen(id: number) {
    this.getAll();
  }
  created() {
    if (this.sLoadOnMount) this.getAll(this.value);
  }
 @Watch("nameValueCode")
  onnameValueCode(value: string) {
    if (value) {
      this.getAll(this.value);
    }
  }
  @Watch("loadWhen")
  onLoadWhen(value: boolean) {
    if (value) {
      this.getAll(this.value);
    }
  }
  @Watch("options")
  onOptionsChange(value: boolean) {
      this.getAll(this.value);
  }

  @Watch("disabled")
  onDisableChange(value: boolean) {
    if (value && this.initOnDisable) {
      this.selected = new BaseModel();
      this.data = [];
      this.onRemove(this.selected);
    }
  }
}
