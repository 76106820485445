export namespace CodigoEstadoSolicitud {
  export const RECIBIDA_CORRECTAMENTE: string = "CE1";
  export const SOLICITUD_APROBADA: string = "CE2";
  export const SOLICITUD_DECLINADA: string = "CE3";
  export const SOLICITUD_ARCHIVADA: string = "CE4";
  export const VALIDACION_DATOS: string = "CE5";
  export const EVALUACION_FINANCIERA: string = "CE6";
  export const HABILITADO: string = "CE7";
  export const NO_HABILITADO: string = "CE8";
  export const PRE_APROBADO: string = "CE9";
  export const EN_PROGRESO: string = "CE10";
  export const GESTION_MATERIALES: string = "CE11";
  export const SOLICITUD_COMPLETADA: string = "CE12";
  export const SELECCION_BENEFICIARIOS: string = "CE13";
  export const NO_CALIFICA: string = "CE14";
  export const NO_SUBIO_DOCUMENTOS_EVALUACION_FINANCIERA: string = "CE15";
}
