











































































import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import BaseVue from "@/core";
import SolicitudProyecto from "@/models/solicitud-proyecto.model";
import { EstadoValidacionRequisitoSolicitud } from "@/enums/estado-validacion-requisito-solicitud";
@Component({})
export default class MetadataInfo extends BaseVue {
  @Prop() solicitud!: SolicitudProyecto;
  public iconStatus: any;
  public validacionRequisitosList: any = [];
  public requisitoList: any = [];
  constructor() {
    super();
    this.getRequisitos();
  }
  getRequisitos() {
    this.validacionRequisitosList = this.solicitud.validacionRequisitos;
    this.requisitoList = [];
    for (let datos of this.validacionRequisitosList) {
      this.iconStatus = this.iconStates(datos.estado);
      this.requisitoList.push({ datos: datos, estilos: this.iconStatus });
    }
    console.log(this.requisitoList);
    console.log(this.requisitoList[0].requisito.id);
  }
  iconStates(value: any) {
    if (value === EstadoValidacionRequisitoSolicitud.CUMPLE) {
      this.iconStatus = {
        type: "is-success",
        icon: "checkbox-marked-circle",
        label: "Cumple",
      };
      return this.iconStatus;
    } else if (value === EstadoValidacionRequisitoSolicitud.NO_CUMPLE) {
      this.iconStatus = {
        type: "is-danger",
        icon: "close-circle",
        label: "No cumple",
      };
      return this.iconStatus;
    } else if (value === EstadoValidacionRequisitoSolicitud.EN_PROCESO) {
      this.iconStatus = { type: "is-info", icon: "clock", label: "En proceso" };
      return this.iconStatus;
    }
  }
  getRequirementDescription(status: number) {
    return this.iconStates(status).label;
  }
}
