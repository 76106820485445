import BTableColumn from '@/models/base/b-table-column.model';
import { RowTable } from '@/typings/b-row-table';
import { BTableColumnType } from '@/enums/b-table-column-type.enum';
import Helpers from '@/core/helpers';
export default class BTableColumnFilter {
    static Value(value: any, column: BTableColumn, props: RowTable<object>) {
        if (value === undefined || value === null || value === '')
            return Helpers.Filters.Empty;

        if (column.type === BTableColumnType.Date)
            return Helpers.Filters.Date(value);

        if (column.type === BTableColumnType.Time)
            return Helpers.Filters.Time(value);

        if (column.type === BTableColumnType.Bool)
            return Helpers.Filters.Boolean(value);

        if (column.type === BTableColumnType.Custom)
            return column.customFilter ? column.customFilter(value) : value;

        return value;
    }
}