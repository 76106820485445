/*  */export default class ApiQueryOption {
    public perpage: number | undefined = undefined
    public page: number | undefined = undefined
    public orderby: string | undefined = undefined
    public include: string | undefined = undefined
    constructor(
        public where: string | undefined = undefined
    ) { 
        this.perpage = this.perpage || (process.env.VUE_APP_PER_PAGE as any)
    }
}