export default class BTreeselectConfig {
    public controller!: string;
    public action!: string;
    public whereParams!: string;
    public whereChildrenParams!: string;

    public placeholder!: string;
    public multiple!: boolean;
    public value!: number | number[] | TreeselectModel | TreeselectModel[] | null;
    public async!: boolean;
    public clerable!: boolean;
    public searchable!: boolean;
    public disabled!: boolean;
    public disableBranchNodes!: boolean;
    public openOnClick!: boolean;
    public openOnFocus!: boolean;
    public clearOnSelect!: boolean;
    public closeOnSelect!: boolean;
    public isDefaultExpanded!: boolean;
    public alwaysOpen!: boolean;
    public autoLoadRootOptions!: boolean;
    public showCount!: boolean;
    public defaultExpandLevel!: number | null;
    public openDirection!: 'auto' | 'below' | 'bottom' | 'above' | 'top';
    public noChildrenText!: string;
    public noOptionsText!: string;
    public noResultsText!: string;
    public loadingText!: string;
    public retryText!: string;
    public retryTitle!: string;
    public required: boolean;

    public loadOptions!: (payload: TreeselectMethodParams) => void;
    public open!: (instanceId: string) => void;
    public input!: (value: number | number[], instanceId: string) => void;
    public close!: (value: number | number[], instanceId: string) => void;
    public select!: (value: TreeselectModel, instanceId: string) => void;
    public deselect!: (value: TreeselectModel, instanceId: string) => void;
    public searchChange!: (query: string, instanceId: string) => void;
    constructor() {
        this.required = false;
        this.init();
    }

    init() {
        this.placeholder = "Seleccionar";
        this.openDirection = "auto";
        this.noChildrenText = "No hay datos...";
        this.loadingText = "Cargando...";
        this.noOptionsText = this.noChildrenText;
        this.retryText = '¿Reintentar?';
        this.retryTitle = 'Click para reintentar';
        this.value = null;
        this.multiple = true;
        this.clerable = false;
        this.searchable = false;
        this.disabled = false;
        this.openOnClick = true;
        this.openOnFocus = false;
        this.clearOnSelect = false;
        this.closeOnSelect = true;
        this.alwaysOpen = false;
        this.async = false;
        this.disableBranchNodes = false;
        this.showCount = false;
        this.isDefaultExpanded = false;
        this.autoLoadRootOptions = false;
    }
}



export class TreeselectModel {
    constructor(public id: string, public label: string, public children?: TreeselectModel[] | null, public item?:any){

    }
}

export interface TreeselectMethodParams {
    parentNode: TreeselectModel;
    action: 'LOAD_ROOT_OPTIONS' | 'LOAD_CHILDREN_OPTIONS' | 'ASYNC_SEARCH';
    callback: Function;
    searchQuery: string;
    instanceId: number;
}