import BaseEntity from "@/models/base/base-entity.model";
import Ciudadano from "./ciudadano.model";
import Contacto from "./contacto.model";
import SolicitudMejoramientoViviendaMaterialParedesVivienda from "./solicitud-mejoramiento-vivienda-material-paredes-vivienda.model";
import SolicitudMejoramientoViviendaMaterialPisoVivienda from "./solicitud-mejoramiento-vivienda-material-piso-vivienda.model";
import SolicitudMejoramientoViviendaMaterialTechoVivienda from "./solicitud-mejoramiento-vivienda-material-techo-vivienda.model";
import SolicitudMejoramientoViviendaRazonDeterioroVivienda from "./solicitud-mejoramiento-vivienda-razon-deterioro-vivienda.model";
import SolicitudMejoramientoViviendaRepararZonaVivienda from "./solicitud-mejoramiento-vivienda-reparar-zona-vivienda.model";
import Proyecto from "./tipo-trabajo.model";

export default class SolicitudMejoramientoVivienda extends BaseEntity {
  codigoSolicitud: string = "";
  solicitanteId: number = 0;
  solicitanteCedula: string = "";
  desarrolladorId: number = 0;
  conyugeCedula: string = "";
  telefonoVivienda: string = "";
  cantidadPersonas?: number = undefined;
  comentarios: string = "";
  direccion: string = "";
  estadoId: number = 0;
  estadoNombre: string = "";
  proyectoId?: number = undefined;
  proyectoNombre: string = "";
  tipoViviendaId?: number = undefined;
  tipoViviendaNombre: string = "";
  sector?: string = undefined;
  provinciaId?: number = undefined;
  provinciaNombre: string = "";
  codigoProvincia: string = "";
  municipioId?: number = undefined;
  municipioNombre: string = "";
  codigoMunicipio: string = "";
  esInformacionVeridica: boolean = false;
  solicitante = new Ciudadano();
  contacto = new Contacto();
  proyecto = new Proyecto();
  materialParedesViviendaOtro: string = "";
  materialPisoViviendaOtro: string = "";
  materialTechoViviendaOtro: string = "";
  razonDeterioroViviendaOtro: string = "";
  repararZonaViviendaOtro: string = "";

  materialParedesViviendas:SolicitudMejoramientoViviendaMaterialParedesVivienda[] = [];
  materialPisoViviendas:SolicitudMejoramientoViviendaMaterialPisoVivienda[] = [];
  materialTechoViviendas:SolicitudMejoramientoViviendaMaterialTechoVivienda[] = [];
  razonDeterioroViviendas:SolicitudMejoramientoViviendaRazonDeterioroVivienda[] = [];
  repararZonaViviendas: SolicitudMejoramientoViviendaRepararZonaVivienda[] = [];

  constructor() {
    super();
    this.id = undefined;
  }
}
