


























import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import BaseVue from "@/core";
import SolicitudProyecto from "@/models/solicitud-proyecto.model";
import TipoVivienda from "@/models/tipo-vivienda.model";
@Component({})
export default class CollapseDetalleSolicitud extends BaseVue {
  @Prop() public value!: SolicitudProyecto;
}
