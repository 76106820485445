import { Sexo } from "@/enums/estado-civil.enum";
import { EstadoCivil } from "@/enums/sexo.enum";
import BaseEntity from "@/models/base/base-entity.model";

export default class Ciudadano extends BaseEntity {
  esDiscapacitado: boolean = false;
  nombres: string = "";
  apellidos: string = "";
  estadoCivil: EstadoCivil | null = null;
  sexo: Sexo = Sexo.MASCULINO;
  fechaNacimiento: string = "";
  cedula: string = "";
  celular: string = "";
  correoElectronico: string = "";
  ingresoMensual?: number = undefined;
  profesionOficio: string = "";
  tipoTrabajoId?: number = undefined;
  tipoTrabajoNombre: string = "";
  conyugeId: number | null = null;
  conyugeCedula: string = "";
  conyuge: Ciudadano | null = null;

  constructor() {
    super();
    this.id = undefined;
  }
}
