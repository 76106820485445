import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import '@/assets/css/themify-icons.css';
export default class FormWizardOptions{
    public nextButtonText!: string;
    public backButtonText!: string;
    public finishButtonText!: string;
    public stepSize!: string;
    public color!: string;
    public errorColor!: string;
    public shape!: string;
    public layout!: string;
    public startIndex!: Number;
    constructor(public title = '', public subtitle = ''){
        this.nextButtonText = "Siguiente";
        this.backButtonText = "Anterior";
        this.finishButtonText = "Enviar";
        this.stepSize = "sm";
        this.color = "rgb(1, 95, 185)";
        this.errorColor = "hsl(348, 100%, 61%)";
        this.shape = "circle";
        this.layout = "horizontal";
        this.startIndex = 0;
    }
}
