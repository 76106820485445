var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-loading',{attrs:{"is-full-page":true,"active":_vm.loading}}),_c('figure',{staticClass:"image main-logo margin-o-auto"},[_c('a',{attrs:{"href":_vm.miviviendaURl}},[_c('img',{staticClass:"pointer",attrs:{"src":require("../assets/img/mived-mivivienda2.png"),"alt":"Placeholder image"}})])]),_c('p',{staticClass:"title has-text-centered"},[_vm._v(" Documentos para evaluación financiera ")]),_c('div',[_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_c('the-mask',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"input input-cedula",attrs:{"disabled":_vm.isLoading,"name":"cedula","data-vv-as":"cédula","mask":'###-#######-#',"type":"text","placeholder":"Digite su número de cédula"},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getSolicitude($event)}},model:{value:(_vm.cedula),callback:function ($$v) {_vm.cedula=$$v},expression:"cedula"}}),_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading,"type":"is-info"},on:{"click":function($event){return _vm.getSolicitude()}}},[_vm._v("Consultar")])],1)],1),_c('div',{staticClass:"catcha"},[(_vm.isRecaptchEnabled)?_c('vue-recaptcha',{staticClass:"catcha-bar",attrs:{"sitekey":_vm.recaptchaKey,"loadRecaptchaScript":true},on:{"verify":_vm.onVerify}}):_vm._e()],1)],1),(!_vm.model)?_c('div',[_c('hr'),_c('solicitud-invalida')],1):(
      _vm.model &&
        _vm.model.solicitanteCedula != 0 &&
        _vm.model.codigoEstado !== _vm.evaluacionFinanciera
    )?_c('div',[_c('hr'),_c('p',{staticClass:"mt-5 mb-5 title has-text-centered error"},[_vm._v(" No califica para este requerimiento ")])]):(
      _vm.model &&
        _vm.model.solicitanteCedula != 0 &&
        _vm.model.codigoEstado === _vm.evaluacionFinanciera &&
        _vm.model.employmentLetterId === null &&
        _vm.model.bankStatementId === null
    )?_c('div',[_c('hr'),_c('div',[_c('div',{staticClass:"mb-5 "},[_vm._v(" Estimado/a "),_c('span',[_c('strong',{staticClass:"has-text-primary is-size-5"},[_vm._v(_vm._s(_vm.model.solicitante.nombres))])]),_vm._v(" , "),_vm._m(0)]),(_vm.showUploadCertificate)?_c('div',{staticClass:"files-container"},[_c('div',{},[_c('p',{staticClass:"title is-5 has-text-centered"},[_vm._v(" Cargar carta de empleo ")]),_c('uploadPDFSOnly',{model:{value:(_vm.employmentLetter),callback:function ($$v) {_vm.employmentLetter=$$v},expression:"employmentLetter"}})],1),_c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"title is-5 has-text-centered"},[_vm._v(" Cargar estado bancario ")]),_c('uploadPDFSOnly',{model:{value:(_vm.bankStatement),callback:function ($$v) {_vm.bankStatement=$$v},expression:"bankStatement"}})],1)]):_vm._e(),(_vm.show)?_c('div',[_c('successComponent')],1):_vm._e(),(_vm.showUploadCertificate && _vm.employmentLetter && _vm.bankStatement)?_c('div',[_c('hr'),_c('div',{staticClass:"save-button-container"},[_c('b-button',{attrs:{"type":"is-success","expanded":""},on:{"click":function($event){return _vm.saveFile()}}},[_vm._v("Subir archivos")])],1)]):_vm._e()])]):(
      _vm.model &&
        _vm.model.solicitanteCedula != 0 &&
        _vm.model.codigoEstado === _vm.evaluacionFinanciera &&
        _vm.model.employmentLetterId !== null &&
        _vm.model.bankStatementId !== null
    )?_c('div',{},[_c('hr'),_c('p',{staticClass:"mt-5 mb-5 title has-text-centered error"},[_vm._v(" Ya subió los documentos para esta solicitud ")])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-2"},[_vm._v(" Para continuar con el proceso es necesario que adjunte los siguientes documentos a la solicitud en formato "),_c('strong',{staticClass:"has-text-primary"},[_vm._v("PDF")]),_vm._v(": ")])}]

export { render, staticRenderFns }