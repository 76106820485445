























































































































import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import BaseVue from "@/core";
import SolicitudMejoramientoVivienda from "@/models/solicitud-mejoramiento-vivienda.model";
@Component({})
export default class SolicitudFormMejoramientoViviendaDetailComponent extends BaseVue {
  @Prop() public value!: SolicitudMejoramientoVivienda;
}
